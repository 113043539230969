<!--
 * @Author: wxb
 * @Date: 2021-06-21 10:27:45
 * @LastEditTime: 2023-03-21 11:02:40
 * @LastEditors: wangxb 18254141586@163.com
 * @Description:
 * @FilePath: \ui-djz\src\views\Vip\index.vue
-->
<template>
  <div class="containner">
    <headerNav></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>加入会员</el-breadcrumb-item>
        <el-breadcrumb-item>充值</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="vip"
           v-show="!payover">
        <div class="vip-title"> 开通VIP会员</div>
        <div class="vip-sub-title">仅限个人使用，如需多人或企业使用请联系客服！</div>
        <div class="vip-setmeal">
          <ul class="setmeal-list clearfix">
            <li class="setmeal-list-item"
                v-for="(item,index) in setmealList"
                :key="index"
                :class="index===activeIdx?'active':''"
                @click="handleSelectSetmeal(index)">
              <div class="setmeal">
                <div class="setmeal-title">{{item.packageName}}</div>
                <div class="setmeal-price">
                  <span class="price">{{item.packageAmount | formatPrice(item.packageDiscount)}}</span>
                  <span class="term">元/<span v-if="item.daysUnit==='1'">天</span>
                    <span v-else-if="item.daysUnit==='2'">月</span>
                    <span v-else-if="item.daysUnit==='3'">季</span>
                    <span v-else-if="item.daysUnit==='4'">年</span>
                    <span v-else-if="item.daysUnit==='5'">周</span></span>
                </div>
                <div class="setmeal-divide"></div>
                <div class="setmeal-desc">开通会员，享受更多权益</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="vip-rights">
          <div class="vip-rights-title">权益中心</div>
          <div class="vip-rights-list">
            <div class="vip-rights-list-item">
              <div class="rights-icon">
                <img src="../../assets/vip/vip-1.png" />
              </div>
              <div class="rights-title">全量机构信息查询</div>
            </div>
            <div class="vip-rights-list-item">
              <div class="rights-icon">
                <img src="../../assets/vip/vip-2.png" />
              </div>
              <div class="rights-title">项目全阶段整体浏览</div>
            </div>
            <div class="vip-rights-list-item">
              <div class="rights-icon">
                <img src="../../assets/vip/vip-3.png" />
              </div>
              <div class="rights-title">文档一键随心下载</div>
            </div>
            <div class="vip-rights-list-item">
              <div class="rights-icon">
                <img src="../../assets/vip/vip-4.png" />
              </div>
              <div class="rights-title">图谱关系全链条呈现</div>
            </div>
            <div class="vip-rights-list-item">
              <div class="rights-icon">
                <img src="../../assets/vip/vip-5.png" />
              </div>
              <div class="rights-title">特色专题内容尊享</div>
            </div>
          </div>
        </div>
        <div class="vip-pay">
          <div class="vip-pay-title">支付方式</div>
          <div class="vip-pay-content">
            <div class="vip-pay-content-qr">
              <div class="qrcode-refresh"
                   v-show="isExpire">
                <img class="refresh"
                     src="../../assets/Login/refresh.png">
                <div class="qrcode-refresh-title">二维码失效，刷新页面重新生成</div>
              </div>
              <div id="qrcode"
                   class="qrcode"
                   ref="qrcode"></div>
              <div class="vip-pay-content-qr-title">微信扫码支付</div>
            </div>
            <div class="vip-pay-content-timeline">
              <el-timeline>
                <el-timeline-item>
                  <div class="timeline-info">
                    <div class="timeline-info-title">支付金额</div>
                    <div class="timeline-info-content price">￥{{payMoney}}</div>
                  </div>
                </el-timeline-item>
                <el-timeline-item>
                  <div class="timeline-info">
                    <div class="timeline-info-title">剩余时间</div>
                    <div class="timeline-info-content">
                      <span class="countDown">{{countDown}}</span>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-info"
           v-show="payover">
        <div class="pay-info-img">
          <img src="../../assets/vip/payover.png">
        </div>
        <div class="pay-info-msg">支付成功</div>
        <div class="pay-info-tools">
          <el-button type="success"
                     @click="handleGoHome">去首页</el-button>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getToken } from '@/utils/auth'
import { getSetmealList, addOrder, checkOrderInfo } from '@/api/vip'
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
export default {
  name: 'VIP',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      setmealList: [],
      activeIdx: 0,
      payMoney: '',
      countDown: '',
      timeSet: '',
      setmealParams: {
        start: 0,
        limit: 10,
        packageStatus: 0
      },
      invalidTime: '',
      orderNo: '',
      isloading: false,
      isExpire: false,
      timeObj: null,
      payover: false
    }
  },
  filters: {
    formatPrice(amount, discount) {
      let singlePrice = 0
      if (discount) {
        singlePrice = parseFloat(amount) * parseFloat(discount)
      } else {
        singlePrice = parseFloat(amount)
      }
      return singlePrice.toFixed(2)
    }
  },
  methods: {
    // 切换套餐，重新生成订单
    handleSelectSetmeal(index) {
      if (this.isloading) {
        return
      }
      this.isloading = true
      this.activeIdx = index
      this.$refs.qrcode.innerHTML = ''
      this.createOrder()
    },
    // 倒计时
    countdown() {
      let [h, m, s] = [2, 0, 0]
      this.countDown = '02:00:00'
      clearInterval(this.timeSet)
      this.timeSet = null
      this.timeSet = setInterval(() => {
        if (s <= 0) {
          s = 60
          if (m <= 0) {
            m = 60
            h--
          }
          m--
        }
        s--

        const hh = h < 10 ? '0' + h : h
        const mm = m < 10 ? '0' + m : m
        const ss = s < 10 ? '0' + s : s
        this.countDown = hh + ':' + mm + ':' + ss
        if (h <= 0 && m <= 0 && s <= 0) {
          clearInterval(this.timeSet)
          this.countDown = '支付超时，请刷新页面'
        }
      }, 1000)
    },
    // 加载套餐信息
    getSetmealLst() {
      getSetmealList(this.setmealParams).then(res => {
        if (res.ok) {
          this.setmealList = res.lst
          this.activeIdx = 0
          this.createOrder()
        } else {
          this.$message.error('套餐信息加载失败')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 创建订单
    createOrder() {
      const params = {
        paymentMethod: 1
      }
      const setmealInfo = this.setmealList[this.activeIdx]
      params.setMealId = setmealInfo.id
      params.packageName = setmealInfo.packageName
      params.packageTerm = setmealInfo.packageTerm
      params.daysUnit = setmealInfo.daysUnit
      params.packageAmount = setmealInfo.packageAmount
      params.packageDiscount = setmealInfo.packageDiscount
      addOrder(params).then(res => {
        if (res.ok) {
          this.isloading = false
          /* eslint-disable no-new */
          new QRCode('qrcode', {
            width: 150,
            height: 150,
            text: res.codeUrl // 二维码地址
          })
          this.invalidTime = res.invalidTime
          this.orderNo = res.orderNo
          this.payMoney = res.paymentAmount
          this.isExpire = false
          this.countdown()
          this.longPoll()
        } else {
          this.$message.error('创建订单失败，请刷新页面')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 长轮询检验订单是否支付完成
    longPoll() {
      const that = this
      const now = new Date()
      if (now > new Date(this.invalidTime)) {
        if (that.timeObj) {
          clearTimeout(that.timeObj)
        }
        this.isExpire = true
        return
      }
      if (that.timeObj) {
        clearTimeout(that.timeObj)
      }
      const params = {
        orderNo: that.orderNo
      }
      checkOrderInfo(params).then(res => {
        if (res.ok) {
          if (res.msg === '支付成功') {
            this.$store.dispatch('getMemberInfo')
            this.payover = true
            if (that.timeObj) {
              clearTimeout(that.timeObj)
            }
          } else {
            that.$nextTick(() => {
              that.timeObj = setTimeout(() => {
                that.longPoll()
              }, 1000 * 1)
            })
          }
        } else {
          that.$nextTick(() => {
            that.timeObj = setTimeout(() => {
              that.longPoll()
            }, 1000 * 1)
          })
        }
      })
    },
    // 回首页
    handleGoHome() {
      this.$router.push({
        name: 'home'
      })
    }
  },
  mounted() {
    if (!getToken()) {
      localStorage.setItem('sourceurl', this.$route.fullPath)
      this.$router.push({
        name: 'login'
      })
    } else {
      this.getSetmealLst()
    }
  },
  beforeRouteLeave(to, form, next) {
    const that = this
    if (that.timeObj) {
      clearTimeout(that.timeObj)
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.vip {
  &-title {
    font-size: 1.8rem;
    height: 5.4rem;
    line-height: 5.4rem;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-sub-title {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    font-weight: 400;
    margin-top: 1.4rem;
    margin-bottom: 3rem;
  }

  &-setmeal {
    .setmeal-list {
      margin-bottom: 3.5rem;
      &-item {
        width: 32rem;
        height: 17.3rem;
        background: #ffffff;
        border: 0.2rem solid rgba(0, 0, 0, 0.15);
        border-radius: 0.4rem;
        float: left;
        cursor: pointer;
        margin-left: 2rem;
        margin-top: 2rem;
        // &:not(:first-child) {
        //   margin-left: 2rem;
        // }

        .setmeal {
          padding: 1.5rem;
          &-title {
            font-size: 1.8rem;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            white-space: nowrap;
          }

          &-price {
            text-align: center;
            .price {
              font-size: 5rem;
              color: #000;
              line-height: 7.3rem;
              height: 7.3rem;
              font-weight: 700;
            }
            .term {
              font-size: 1.4rem;
              color: rgba(0, 0, 0, 0.65);
              font-weight: 400;
            }
          }

          &-divide {
            margin-top: 0.5rem;
            width: 28.1rem;
            height: 0.1rem;
            background: rgba(0, 0, 0, 0.1);
          }

          &-desc {
            height: 4.5rem;
            line-height: 4.5rem;
            font-size: 1.4rem;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            font-weight: 400;
          }
        }

        &.active {
          border-color: #ffb248;
          background: url("../../assets/vip/selected.png");
          background-repeat: no-repeat;
          background-position: 270px 125px;
          .setmeal {
            .price {
              color: #ffb248;
            }
          }
        }
      }
    }
  }

  &-rights {
    &-title {
      height: 5.4rem;
      line-height: 5.4rem;
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      font-weight: 700;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &-list {
      display: flex;
      align-content: space-around;
      align-items: center;
      padding-top: 5rem;
      margin-bottom: 3rem;
      &-item {
        flex: 1;
        .rights {
          &-icon {
            text-align: center;
          }

          &-title {
            text-align: center;
            font-size: 1.8rem;
            color: rgba(0, 0, 0, 0.65);
            font-weight: 400;
            padding-top: 1.5rem;
          }
        }
      }
    }
  }

  &-pay {
    &-title {
      height: 5.4rem;
      line-height: 5.4rem;
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      font-weight: 700;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &-content {
      display: flex;
      &-qr {
        width: 20rem;
        text-align: center;
        position: relative;
        .qrcode-refresh {
          width: 15.8rem;
          height: 16.8rem;
          position: absolute;
          background: rgba(255, 255, 255, 0.8);
          left: 2rem;
          cursor: pointer;
          padding-top: 51px;
          text-align: center;

          .refresh {
            width: 3.9rem;
            height: auto;
          }

          &-title {
            color: #000;
            font-size: 1.2rem;
            padding: 0.2rem;
          }
        }
        .qrcode {
          width: 15rem;
          height: 15rem;
          margin: 0 auto;
          margin-top: 0.8rem;
        }

        &-title {
          font-size: 1.8rem;
          color: rgba(0, 0, 0, 0.65);
          text-align: left;
          font-weight: 400;
          margin-top: 0.5rem;
          text-align: center;
        }
      }

      &-timeline {
        flex: 1;
        padding: 1.5rem;
        .timeline-info {
          &-title {
            font-size: 1.8rem;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            font-weight: 500;
          }

          &-content {
            margin-top: 0.5rem;
          }

          .price {
            font-size: 2.4rem;
            color: #ffb248;
          }

          .countDown {
            display: inline-block;
            background: #ffefef;
            padding: 0.5rem;
            font-size: 2.4rem;
            color: #ff4141;
            line-height: 3.3rem;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.pay-info {
  height: 75rem;
  padding-top: 15rem;
  &-img {
    text-align: center;
  }

  &-msg {
    text-align: center;
    font-size: 2.5rem;
  }

  &-tools {
    text-align: center;
    padding: 4rem;
  }
}
</style>
